/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ligues': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.163 2.545L3.534 7.73a6.999 6.999 0 013.05-1.918L4.91 1.628A1 1 0 003.982 1h-2.98a1 1 0 00-.839 1.545zm16.992 0L13.783 7.73a6.999 6.999 0 00-3.05-1.918l1.675-4.185A1 1 0 0113.336 1h2.98a1 1 0 01.839 1.545zM11.659 11.5a3 3 0 11-6 0 3 3 0 016 0zm2 0a5 5 0 11-10 0 5 5 0 0110 0z" _fill="#898B9B"/>'
  }
})
